import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import Layout from "../../components/layout-en"
import ImageIntervention from "../../components/imageIntervention-en"
import ImageClinique from "../../components/imageClinique-en"
import ASavoirIntervention from "../../components/aSavoirIntervention-en"
import Share from "../../components/share-en"
import SEO from "../../components/seo-en"

const ClinicPage = () => (
  <Layout pathFr="/hospitalisation/">
    <SEO
      title="Clinic specialized in hand, elbow and shoulder surgery"
      description=" Hand, elbow, shoulder and collarbone surgeries are performed by Dr.
      Philippe Roure at the Clinique du Sport de Paris (5th arrondissement)."
    />
    <Helmet>
      <link
        rel="alternate"
        hreflang="fr"
        href={`https://www.docteurphilipperoure.com/hospitalisation/`}
      />
    </Helmet>
    <ImageIntervention />
    <WrapperTitle>
      <Breadcrumb>
        <Link to="/en/">Home </Link>
      </Breadcrumb>
      <H1>Clinic specialized in hand, elbow and shoulder surgery</H1>
      <p className="intro">
        Hand, elbow, shoulder and collarbone surgeries are performed by Dr.
        Philippe Roure at the Clinique du Sport de Paris (5<sup>th</sup>{" "}
        arrondissement). This establishment is highly-specialised in orthopaedic
        and arthroscopic surgery and its operating theatre is particularly
        suitable for hand, elbow and shoulder surgery.
      </p>
    </WrapperTitle>
    <Wrapper>
      <Row>
        <Col1>
          <H2>
            Clinique du <span>Sport</span>
          </H2>
          <p>
            Procedures are performed at the{" "}
            <strong>
              <a
                href="http://clinique-du-sport-paris.ramsaygds.fr/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Clinique du Sport
              </a>
            </strong>{" "}
            (36&nbsp;Boulevard Saint Marcel, 75005 Paris). This
            highly-specialised establishment for orthopaedic and arthroscopic
            surgery has been treating athletes for many years.{" "}
          </p>
          <p>
            Its <strong>operating theatre</strong> is particularly suitable for
            orthopaedic surgery procedures.
          </p>
          <p>
            The clinic is part of the{" "}
            <strong>
              <a
                href="https://ramsaygds.fr/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Ramsay-G&eacute;n&eacute;rale de Sant&eacute; group
              </a>
            </strong>
            , and meets the most stringent safety standards.
          </p>
        </Col1>
        <Col2>
          <ImageClinique />
        </Col2>
      </Row>
      <Row>
        <Col3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10504.041204804107!2d2.3579524!3d48.8389422!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ed01a548cf442fd!2sClinique+du+Sport+-+Ramsay+G%C3%A9n%C3%A9rale+de+Sant%C3%A9!5e0!3m2!1sfr!2sus!4v1558477994570!5m2!1sfr!2sus"
            width="100%"
            height="295"
            title="Map"
            frameborder="0"
            allowfullscreen
          />
        </Col3>
        <Col4>
          <H2>
            Types of <span>hospitalisation</span>
          </H2>
          <p>
            Depending on your case, procedures can be day-cases (
            <strong>outpatient</strong>) or require an{" "}
            <strong>in-patient</strong> stay of a few days.
          </p>
          <p>
            There are several room categories, from single rooms to suites with
            hotel service, and several in-patient plans that my assistants will
            be able <strong>to propose and explain</strong> to you.
          </p>
          <p>
            My assistant{" "}
            <strong>
              <Link to="/equipe/">Paula</Link>
            </strong>{" "}
            is on hand to assist you personally during your stay and in your
            dealings with the clinic. You will be greeted at the clinic by{" "}
            <strong>
              <Link to="/equipe/">Cyril</Link>
            </strong>
            , who is a registered operating theatre nurse and my operating
            assistant. He will also be with you in the operating room.
          </p>
        </Col4>
      </Row>
      <ASavoirIntervention />
      <Share
        slug="https://www.docteurphilipperoure.com/en/clinic/"
        title="Clinic specialized in hand, elbow and shoulder surgery"
      />
    </Wrapper>
  </Layout>
)

export default ClinicPage

const WrapperTitle = styled.div`
  background-color: white;
  width: 78%;
  margin: 0 auto;
  border-radius: 7px;
  padding: 1.5em 2em 0.9em;
  margin-bottom: 45px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
    padding: 1em 1em 0.5em;
    margin-bottom: 25px;
    border-radius: 0 0 7px 7px;
  }
`

const Breadcrumb = styled.div`
  color: #e6ae48;
  text-align: center;
  font-size: 0.9em;
  line-height: 1em;
  font-weight: 400;
  a {
    color: #919090;
    text-decoration: none;
  }
  @media (max-width: 678px) {
    font-size: 0.87em;
    margin-bottom: 10px;
  }
`

const H1 = styled.h1`
  font-size: 2.1em;
  @media (max-width: 678px) {
    font-size: 1.82em;
    line-height: 1.1em;
  }
`

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 2.25em 2em 1em 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    border-radius: 7px;
    padding: 1em;
  }
`

const H2 = styled.h2`
  font-weight: 400;
  line-height: 1.38em;
  font-size: 1.9em;
  margin-block-start: 0;
  span {
    font-weight: 800;
  }
  @media (max-width: 678px) {
    font-size: 1.7em;
    line-height: 1.3em;
  }
`

const Row = styled.div`
  display: flex;
  margin: 15px 0 60px;
  clear: both;
  @media (max-width: 678px) {
    flex-wrap: wrap;
    margin: 0px 0 30px;
  }
`

const Col1 = styled.div`
  flex: 52%;
  padding-right: 10px;
  @media (max-width: 678px) {
    padding: 12px 0 0;
    order: 2;
    flex-basis: 100%;
  }
`

const Col2 = styled.div`
  flex: 48%;
  padding-left: 10px;
  @media (max-width: 678px) {
    padding: 0;
    order: 1;
    flex-basis: 100%;
  }
`

const Col3 = styled.div`
  flex: 39%;
  @media (max-width: 678px) {
    flex-basis: 100%;
    padding: 0;
  }
`

const Col4 = styled.div`
  flex: 61%;
  padding-left: 25px;
  @media (max-width: 678px) {
    flex-basis: 100%;
    padding: 12px 0 0;
  }
`
